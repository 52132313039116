var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Sample request details "), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3",
      "sm": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.requested_by.id,
      "value": _vm.data.requested_by,
      "label": _vm.controls.requested_by.label,
      "allow-empty": false,
      "readonly": _vm.controls.requested_by.readonly,
      "options": _vm.controls.requested_by.options,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.account.id,
      "width": 25,
      "value": _vm.data.account,
      "loading": _vm.controls.account.loading,
      "async": true,
      "label": _vm.controls.account.label,
      "readonly": _vm.controls.account.readonly,
      "options": _vm.controls.account.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "link-mode": true,
      "required": true
    },
    on: {
      "async-search": _vm.onAccountSearch,
      "link-clicked": _vm.onAccountLinkClicked,
      "changed": _vm.updateSelect
    }
  }), _c('b-button', {
    staticClass: "mt-2 ml-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.addAccount();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.distributor.id,
      "width": 25,
      "value": _vm.data.distributor,
      "loading": _vm.controls.distributor.loading,
      "label": _vm.controls.distributor.label,
      "readonly": _vm.controls.distributor.readonly,
      "options": _vm.controls.distributor.options,
      "allow-empty": true,
      "multiple": false,
      "mode": _vm.mode,
      "link-mode": true,
      "required": false
    },
    on: {
      "link-clicked": _vm.onDistributorLinkClicked,
      "changed": _vm.updateSelect
    }
  }), _c('b-button', {
    staticClass: "mt-2 ml-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.addAccount();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.contact.id,
      "width": 25,
      "value": _vm.data.contact,
      "loading": _vm.controls.contact.loading,
      "label": _vm.controls.contact.label,
      "readonly": _vm.controls.contact.readonly,
      "options": _vm.controls.contact.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "link-mode": true,
      "required": false
    },
    on: {
      "link-clicked": _vm.onContactLinkClicked,
      "changed": _vm.updateSelect
    }
  }), _c('b-button', {
    staticClass: "mt-2 ml-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.addContact();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)], 1)])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.date_needed.id,
      "value-single": _vm.data.date_needed,
      "label": _vm.controls.date_needed.label,
      "readonly": _vm.controls.date_needed.readonly,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.reason.id,
      "value": _vm.data.reason,
      "label": _vm.controls.reason.label,
      "readonly": _vm.controls.reason.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-switch', {
    attrs: {
      "id": _vm.controls.barrel_sample.id,
      "value": _vm.data.barrel_sample,
      "readonly": _vm.controls.barrel_sample.readonly,
      "label": _vm.controls.barrel_sample.label
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_vm.data.pos_task.id ? _c('inline-select', {
    attrs: {
      "id": _vm.controls.pos_task.id,
      "value": _vm.data.pos_task,
      "loading": _vm.controls.pos_task.loading,
      "async": false,
      "label": _vm.controls.pos_task.label,
      "readonly": _vm.controls.pos_task.readonly,
      "options": _vm.controls.pos_task.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "link-mode": true,
      "required": false
    },
    on: {
      "link-clicked": _vm.onPosTaskLinkClicked,
      "changed": _vm.updateSelect
    }
  }) : _vm._e()], 1)], 1), _c('h5', [_vm._v("Notes")]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.notes.id,
      "value": _vm.data.notes,
      "hide-label": true,
      "label": _vm.controls.notes.label,
      "readonly": _vm.controls.notes.readonly,
      "mode": _vm.mode,
      "required": false,
      "is-text-area": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('hr'), _c('h5', [_vm._v(" Request items ")]), _c('hr'), _vm.data.isReviewer || !_vm.data.reviewed ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-button-group', {
    staticClass: "p-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.dataTable.isInserting,
      "title": "Add POS"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem('POS');
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "tshirt"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "type": "button",
      "disabled": _vm.dataTable.isInserting,
      "title": "Add product sample"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem('Sample');
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "wine-bottle"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.dataTable.isInserting,
      "title": "Add barrel sample"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem('Barrel');
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "coins"
    }
  }), _vm._v("   "), _vm.data.new_sample_email_sent && _vm.data.linked_barrels_count === 0 ? _c('font-awesome-icon', {
    staticStyle: {
      "color": "orange"
    },
    attrs: {
      "title": "New barrel sample has been requested",
      "icon": "hourglass-start"
    }
  }) : _vm._e(), _vm.data.linked_barrels_count > 0 ? _c('font-awesome-icon', {
    staticStyle: {
      "color": "red"
    },
    attrs: {
      "title": "Barrels linked to the request: ".concat(_vm.data.linked_barrels, " "),
      "icon": "exclamation"
    }
  }) : _vm._e()], 1), !(_vm.mode === this.$constants.FORM_MODE.CREATE) ? _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.dataTable.isInserting,
      "title": "Request new barrel sample"
    },
    on: {
      "click": function click($event) {
        return _vm.openNewSampleRequestModal('Barrel');
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "envelope"
    }
  })], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('b-overlay', {
    attrs: {
      "show": _vm.dataTable.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_vm.dataTable.dataSet.length === 0 ? _c('div', {
    staticClass: "p-3"
  }, [_vm._v(" No data available ")]) : _vm._e(), _vm.dataTable.dataSet.length ? _c('v-client-table', {
    ref: "dataTable",
    attrs: {
      "name": "client_table_sample_request_submission",
      "options": _vm.dataTable.options,
      "columns": _vm.dataTable.columns
    },
    on: {
      "filter": _vm.onFilter,
      "update": _vm.onInlineTableUpdate
    },
    scopedSlots: _vm._u([{
      key: "Project",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('b-link', {
          attrs: {
            "href": "".concat(row['Project Url'])
          }
        }, [_vm._v(" " + _vm._s(row["Project ID"]) + " ")])];
      }
    }, _vm._l(_vm.dataTable.options.editableColumns, function (column, index) {
      return {
        key: column,
        fn: function fn(_ref2) {
          var row = _ref2.row,
            update = _ref2.update,
            setEditing = _ref2.setEditing,
            isEditing = _ref2.isEditing,
            revertValue = _ref2.revertValue;
          return [!isEditing() ? _c('span', {
            key: "dts1-".concat(index),
            on: {
              "click": function click($event) {
                return setEditing(true);
              }
            }
          }, [_vm._v(_vm._s(row[column]))]) : _vm._e(), !isEditing() && !row[column] ? _c('span', {
            key: "dts2-".concat(index),
            staticClass: "pl-5",
            on: {
              "click": function click($event) {
                return setEditing(true);
              }
            }
          }, [_c('b-img')], 1) : _vm._e(), isEditing() ? _c('span', {
            key: "dts3-".concat(index),
            staticClass: "d-flex flex-row align-items-center"
          }, [isEditing() && column === 'Quantity' ? _c('b-form-input', {
            staticStyle: {
              "width": "150px"
            },
            attrs: {
              "type": "number"
            },
            on: {
              "input": function input($event) {
                return _vm.onColumnUpdate(row['ID'], column, row[column]);
              }
            },
            model: {
              value: row[column],
              callback: function callback($$v) {
                _vm.$set(row, column, $$v);
              },
              expression: "row[column]"
            }
          }) : _vm._e(), isEditing() && column === 'Item' ? _c('multiselect', {
            attrs: {
              "id": row['ID'] + ':' + column,
              "track-by": "id",
              "label": "label",
              "value": _vm.rowDropdown(row, row.Type).value,
              "options": _vm.dropdown(row.Type).options,
              "show-labels": false,
              "placeholder": "",
              "multiple": _vm.dropdown(row.Type).multiple,
              "allow-empty": _vm.dropdown(row.Type).allowEmpty
            },
            on: {
              "input": _vm.onChangeSelect
            }
          }) : _vm._e(), !_vm.isNewRecord(row) ? _c('span', {
            staticClass: "btn btn-success btn-sm ml-2",
            on: {
              "click": function click($event) {
                if (_vm.validateCell(column, row[column])) {
                  update(row[column]);
                  setEditing(false);
                }
              }
            }
          }, [_c('font-awesome-icon', {
            attrs: {
              "icon": "check"
            }
          })], 1) : _vm._e(), !_vm.isNewRecord(row) ? _c('span', {
            staticClass: "btn btn-danger btn-sm",
            on: {
              "click": function click($event) {
                revertValue();
                setEditing(false);
              }
            }
          }, [_c('font-awesome-icon', {
            attrs: {
              "icon": "ban"
            }
          })], 1) : _vm._e()], 1) : _vm._e()];
        }
      };
    }), {
      key: "Actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('clip-loader', {
          staticClass: "mt-1",
          attrs: {
            "size": 14,
            "color": "#36D7B7",
            "loading": _vm.isNewRecord(props.row) && props.row.isInserting === true
          }
        }), _vm.isNewRecord(props.row) && !props.row.isInserting ? _c('div', {
          staticClass: "btn-group"
        }, [_c('b-button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": _vm.saveNewRecord
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        })], 1), _c('b-button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": _vm.revertNewRecord
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        })], 1)], 1) : _vm._e(), _vm.isBarrelActionsVisible(props.row) ? _c('div', {
          staticClass: "btn-group"
        }, [!_vm.isNewRecord(props.row) && props.row.Type === 'Barrel' && Number.isInteger(+props.row.ID) && props.row.Status === 'Selected' ? _c('b-button', {
          staticClass: "btn btn-success btn-sm",
          attrs: {
            "title": "Mark barrel as sold"
          },
          on: {
            "click": function click($event) {
              return _vm.sellBarrel(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          staticClass: "ml-1 mr-1",
          attrs: {
            "icon": "dollar-sign"
          }
        })], 1) : _vm._e(), !_vm.isNewRecord(props.row) && props.row.Type === 'Barrel' && Number.isInteger(+props.row.ID) && props.row.Status === 'Selected' ? _c('b-button', {
          staticClass: "btn btn-warning btn-sm",
          attrs: {
            "title": "Deselect barrel"
          },
          on: {
            "click": function click($event) {
              return _vm.deselectBarrel(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "undo"
          }
        })], 1) : _vm._e()], 1) : _vm._e(), !_vm.isNewRecord(props.row) && (_vm.data.isReviewer || !_vm.data.reviewed) && _vm.isBarrelActionsVisible(props.row) ? _c('b-button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1) : _vm._e()], 1);
      }
    }], null, true),
    model: {
      value: _vm.dataTable.dataSet,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "dataSet", $$v);
      },
      expression: "dataTable.dataSet"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Review details")])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.reviewed_by.id,
      "value": _vm.data.reviewed_by,
      "label": _vm.controls.reviewed_by.label,
      "readonly": _vm.controls.reviewed_by.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.date_reviewed.id,
      "value": _vm.data.date_reviewed,
      "label": _vm.controls.date_reviewed.label,
      "readonly": _vm.controls.date_reviewed.readonly,
      "mode": _vm.mode,
      "required": false
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center"
  }, [!(_vm.mode === this.$constants.FORM_MODE.CREATE) && !_vm.data.reviewed && _vm.data.isReviewer ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.setReviewed();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "signature"
    }
  }), _vm._v(" Mark as Reviewed ")], 1) : _vm._e()], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Shipping details")])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.ship_name.id,
      "value": _vm.data.ship_name,
      "label": _vm.controls.ship_name.label,
      "readonly": _vm.controls.ship_name.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.ship_phone.id,
      "value": _vm.data.ship_phone,
      "label": _vm.controls.ship_phone.label,
      "readonly": _vm.controls.ship_phone.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.delivery_type.id,
      "value": _vm.data.delivery_type,
      "loading": _vm.controls.delivery_type.loading,
      "label": _vm.controls.delivery_type.label,
      "readonly": _vm.controls.delivery_type.readonly,
      "options": _vm.controls.delivery_type.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center"
  }, [_vm.data.reviewed && _vm.data.delivery_type.label === 'Ship' && !_vm.data.shipped ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "disabled": !(_vm.data.date_shipped !== '' && _vm.data.tracking !== ''),
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.setShipped();
      }
    }
  }, [!_vm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "shipping-fast"
    }
  }) : _vm._e(), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), _vm._v(" Mark as Shipped ")], 1) : _vm._e(), _vm.data.reviewed && _vm.data.delivery_type.label === 'Pick Up' && !_vm.data.pickedup ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "disabled": !(_vm.data.date_pickup !== ''),
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.setPickedUp();
      }
    }
  }, [!_vm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "box-open"
    }
  }) : _vm._e(), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), _vm._v(" Mark as Picked up ")], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.shipped_by.id,
      "value": _vm.data.shipped_by,
      "loading": _vm.controls.shipped_by.loading,
      "label": _vm.controls.shipped_by.label,
      "readonly": _vm.controls.shipped_by.readonly,
      "options": _vm.controls.shipped_by.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.tracking.id,
      "value": _vm.data.tracking,
      "label": _vm.controls.tracking.label,
      "readonly": _vm.controls.tracking.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_vm.data.delivery_type.label === 'Ship' ? _c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.date_shipped.id,
      "value-single": _vm.data.date_shipped,
      "label": _vm.controls.date_shipped.label,
      "readonly": _vm.controls.date_shipped.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateDateField
    }
  }) : _vm._e(), _vm.data.delivery_type.label === 'Pick Up' ? _c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.date_pickup.id,
      "value-single": _vm.data.date_pickup,
      "label": _vm.controls.date_pickup.label,
      "readonly": _vm.controls.date_pickup.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateDateField
    }
  }) : _vm._e()], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('h5', [_vm._v("Shipping address")])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.street.id,
      "value": _vm.data.street,
      "label": _vm.controls.street.label,
      "readonly": _vm.controls.street.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.country.id,
      "value": _vm.data.country,
      "label": _vm.controls.country.label,
      "readonly": _vm.controls.country.readonly,
      "options": _vm.controls.country.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.state.id,
      "value": _vm.data.state,
      "label": _vm.controls.state.label,
      "readonly": _vm.controls.state.readonly,
      "taggable": true,
      "options": _vm.controls.state.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.city.id,
      "value": _vm.data.city,
      "label": _vm.controls.city.label,
      "readonly": _vm.controls.city.readonly,
      "taggable": true,
      "options": _vm.controls.city.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.zip.id,
      "value": _vm.data.zip,
      "label": _vm.controls.zip.label,
      "readonly": _vm.controls.zip.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1)]), _vm.mode !== this.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e(), _vm.mode !== this.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.projects.title
    }
  }, [_c('projects-table', {
    ref: "projects-table",
    attrs: {
      "show-add-button": false,
      "projects": _vm.relatedProjects
    },
    on: {
      "loaded": _vm.onProjectsLoad
    }
  })], 1) : _vm._e()], 1), _vm.action !== 'preview' ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Sample requests'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Sample request submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Sample request submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  }) : _vm._e()], 1), _c('b-modal', {
    ref: "contact-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.contactPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openContactInNewWindow(_vm.contactPreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closeContactPreview();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('ContactSubmissionForm', {
    attrs: {
      "id": _vm.contactPreviewModal.id,
      "action": "preview"
    }
  })], 1)], 1), _c('b-modal', {
    ref: "account-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.accountPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openAccountInNewWindow(_vm.accountPreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closeAccountPreview();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('AccountSubmissionForm', {
    attrs: {
      "id": _vm.accountPreviewModal.id,
      "action": "preview"
    }
  })], 1)], 1), _c('b-modal', {
    ref: "pos-task-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.posTaskPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openTaskInNewWindow(_vm.posTaskPreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closeTaskPreview();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('TaskSubmissionForm', {
    attrs: {
      "id": _vm.posTaskPreviewModal.id,
      "action": "view"
    }
  })], 1)], 1), _c('b-modal', {
    ref: "request-sample-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "md",
      "title": "New sample request"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": "new-sample-request-sender",
      "value": _vm.newSampleRequestMail.sender_name,
      "hide-label": false,
      "label": "Sender",
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-input', {
    ref: "new-sample-request-recipient",
    attrs: {
      "id": "new-sample-request-recipient",
      "value": _vm.newSampleRequestMail.recipient_email,
      "hide-label": false,
      "label": "Recipient",
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onNewSampleRequestRecipientChanged
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12"
    }
  }, [_c('inline-input', {
    ref: "new-sample-request-message",
    attrs: {
      "id": "new-sample-request-message",
      "value": _vm.newSampleRequestMail.message,
      "hide-label": false,
      "label": "Message",
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true,
      "is-text-area": true
    },
    on: {
      "changed": _vm.onNewSampleRequestMessageChanged
    }
  })], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.newSampleRequestMail.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.sendNewSampleRequestMail();
      }
    }
  }, [_vm.newSampleRequestMail.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.newSampleRequestMail.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "paper-plane"
    }
  }) : _vm._e(), _vm._v(" Send mail ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeNewSampleRequestModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }