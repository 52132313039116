<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <form>
            <h5>
              Sample request details
              <b-spinner v-if="isLoading" small type="grow" />
            </h5>

            <hr />
            <b-row>
              <b-col lg="3" md="3" sm="3">
                <inline-select
                  :id="controls.requested_by.id"
                  :value="data.requested_by"
                  :label="controls.requested_by.label"
                  :allow-empty="false"
                  :readonly="controls.requested_by.readonly"
                  :options="controls.requested_by.options"
                  :mode="mode"
                  :required="true"
                  @changed="updateSelect"
                />
              </b-col>
              <b-col lg="3" md="3">
                <div class="d-flex align-items-center">
                  <inline-select
                    :id="controls.account.id"
                    :width="25"
                    :value="data.account"
                    :loading="controls.account.loading"
                    :async="true"
                    @async-search="onAccountSearch"
                    :label="controls.account.label"
                    :readonly="controls.account.readonly"
                    :options="controls.account.options"
                    :allow-empty="false"
                    :multiple="false"
                    :mode="mode"
                    :link-mode="true"
                    @link-clicked="onAccountLinkClicked"
                    @changed="updateSelect"
                    :required="true"
                  />

                  <b-button
                    class="mt-2 ml-1"
                    variant="outline-dark"
                    @click="addAccount()"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </div>
              </b-col>
              <b-col lg="3" md="3">
                <div class="d-flex align-items-center">
                  <inline-select
                    :id="controls.distributor.id"
                    :width="25"
                    :value="data.distributor"
                    :loading="controls.distributor.loading"
                    :label="controls.distributor.label"
                    :readonly="controls.distributor.readonly"
                    :options="controls.distributor.options"
                    :allow-empty="true"
                    :multiple="false"
                    :mode="mode"
                    :link-mode="true"
                    @link-clicked="onDistributorLinkClicked"
                    @changed="updateSelect"
                    :required="false"
                  />

                  <b-button
                    class="mt-2 ml-1"
                    variant="outline-dark"
                    @click="addAccount()"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </div>
              </b-col>
              <b-col lg="3" md="3">
                <div class="d-flex align-items-center">
                  <inline-select
                    :id="controls.contact.id"
                    :width="25"
                    :value="data.contact"
                    :loading="controls.contact.loading"
                    :label="controls.contact.label"
                    :readonly="controls.contact.readonly"
                    :options="controls.contact.options"
                    :allow-empty="false"
                    :multiple="false"
                    :mode="mode"
                    :link-mode="true"
                    @link-clicked="onContactLinkClicked"
                    @changed="updateSelect"
                    :required="false"
                  />

                  <b-button
                    class="mt-2 ml-1"
                    variant="outline-dark"
                    @click="addContact()"
                  >
                    <font-awesome-icon icon="plus" />
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3">
                <inline-date-picker
                  :id="controls.date_needed.id"
                  :value-single="data.date_needed"
                  :label="controls.date_needed.label"
                  :readonly="controls.date_needed.readonly"
                  :mode="mode"
                  :required="true"
                  @changed="updateDateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.reason.id"
                  :value="data.reason"
                  :label="controls.reason.label"
                  :readonly="controls.reason.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-switch
                  :id="controls.barrel_sample.id"
                  :value="data.barrel_sample"
                  :readonly="controls.barrel_sample.readonly"
                  :label="controls.barrel_sample.label"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-select
                  v-if="data.pos_task.id"
                  :id="controls.pos_task.id"
                  :value="data.pos_task"
                  :loading="controls.pos_task.loading"
                  :async="false"
                  :label="controls.pos_task.label"
                  :readonly="controls.pos_task.readonly"
                  :options="controls.pos_task.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  :link-mode="true"
                  @link-clicked="onPosTaskLinkClicked"
                  @changed="updateSelect"
                  :required="false"
                />
              </b-col>
            </b-row>
            <h5>Notes</h5>

            <b-row>
              <b-col lg="12" md="12">
                <inline-input
                  :id="controls.notes.id"
                  :value="data.notes"
                  :hide-label="true"
                  :label="controls.notes.label"
                  :readonly="controls.notes.readonly"
                  :mode="mode"
                  :required="false"
                  :is-text-area="true"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <hr />

            <h5>
              Request items
            </h5>
            <hr />
            <b-row v-if="data.isReviewer || !data.reviewed">
              <b-col lg="4">
                <b-button-group class="p-2">
                  <b-button
                    variant="outline-dark"
                    :disabled="dataTable.isInserting"
                    @click="addItem('POS')"
                    title="Add POS"
                  >
                    <font-awesome-icon icon="tshirt" />
                  </b-button>
                  <b-button
                    variant="outline-dark"
                    type="button"
                    :disabled="dataTable.isInserting"
                    @click="addItem('Sample')"
                    title="Add product sample"
                  >
                    <font-awesome-icon icon="wine-bottle" />
                  </b-button>
                  <b-button
                    variant="outline-dark"
                    :disabled="dataTable.isInserting"
                    @click="addItem('Barrel')"
                    title="Add barrel sample"
                  >
                    <font-awesome-icon icon="coins" />
                    &nbsp;
                    <font-awesome-icon
                      v-if="
                        data.new_sample_email_sent &&
                          data.linked_barrels_count === 0
                      "
                      style="color:orange;"
                      title="New barrel sample has been requested"
                      icon="hourglass-start"
                    />
                    <font-awesome-icon
                      v-if="data.linked_barrels_count > 0"
                      style="color:red;"
                      :title="
                        `Barrels linked to the request: ${data.linked_barrels} `
                      "
                      icon="exclamation"
                    />
                  </b-button>
                  <b-button
                    v-if="!(mode === this.$constants.FORM_MODE.CREATE)"
                    variant="outline-dark"
                    :disabled="dataTable.isInserting"
                    @click="openNewSampleRequestModal('Barrel')"
                    title="Request new barrel sample"
                  >
                    <font-awesome-icon icon="envelope" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-overlay
                  :show="dataTable.isLoading"
                  :opacity="0.5"
                  spinner-variant="secondary"
                  rounded="sm"
                >
                  <div v-if="dataTable.dataSet.length === 0" class="p-3">
                    No data available
                  </div>

                  <v-client-table
                    ref="dataTable"
                    name="client_table_sample_request_submission"
                    v-if="dataTable.dataSet.length"
                    @filter="onFilter"
                    @update="onInlineTableUpdate"
                    v-model="dataTable.dataSet"
                    :options="dataTable.options"
                    :columns="dataTable.columns"
                  >
                    <template slot="Project" slot-scope="{ row }">
                      <b-link :href="`${row['Project Url']}`">
                        {{ row["Project ID"] }}
                      </b-link>
                    </template>

                    <template
                      v-for="(column, index) in dataTable.options
                        .editableColumns"
                      :slot="column"
                      slot-scope="{
                        row,
                        update,
                        setEditing,
                        isEditing,
                        revertValue
                      }"
                    >
                      <span
                        :key="`dts1-${index}`"
                        @click="setEditing(true)"
                        v-if="!isEditing()"
                        >{{ row[column] }}</span
                      >
                      <span
                        :key="`dts2-${index}`"
                        @click="setEditing(true)"
                        v-if="!isEditing() && !row[column]"
                        class="pl-5"
                      >
                        <b-img
                      /></span>

                      <span
                        :key="`dts3-${index}`"
                        class="d-flex flex-row align-items-center"
                        v-if="isEditing()"
                      >
                        <b-form-input
                          v-if="isEditing() && column === 'Quantity'"
                          style="width: 150px;"
                          type="number"
                          v-model="row[column]"
                          @input="
                            onColumnUpdate(row['ID'], column, row[column])
                          "
                        />

                        <multiselect
                          :id="row['ID'] + ':' + column"
                          v-if="isEditing() && column === 'Item'"
                          track-by="id"
                          label="label"
                          :value="rowDropdown(row, row.Type).value"
                          @input="onChangeSelect"
                          :options="dropdown(row.Type).options"
                          :show-labels="false"
                          placeholder=""
                          :multiple="dropdown(row.Type).multiple"
                          :allow-empty="dropdown(row.Type).allowEmpty"
                        />

                        <span
                          class="btn btn-success btn-sm ml-2"
                          @click="
                            if (validateCell(column, row[column])) {
                              update(row[column]);
                              setEditing(false);
                            }
                          "
                          v-if="!isNewRecord(row)"
                        >
                          <font-awesome-icon icon="check"
                        /></span>
                        <span
                          class="btn btn-danger btn-sm"
                          @click="
                            revertValue();
                            setEditing(false);
                          "
                          v-if="!isNewRecord(row)"
                        >
                          <font-awesome-icon icon="ban"
                        /></span>
                      </span>
                    </template>

                    <div slot="Actions" slot-scope="props">
                      <clip-loader
                        class="mt-1"
                        :size="14"
                        color="#36D7B7"
                        :loading="
                          isNewRecord(props.row) &&
                            props.row.isInserting === true
                        "
                      />
                      <div
                        class="btn-group"
                        v-if="isNewRecord(props.row) && !props.row.isInserting"
                      >
                        <b-button
                          class="btn btn-success btn-sm"
                          @click="saveNewRecord"
                        >
                          <font-awesome-icon icon="check" />
                        </b-button>
                        <b-button
                          class="btn btn-danger btn-sm"
                          @click="revertNewRecord"
                        >
                          <font-awesome-icon icon="ban" />
                        </b-button>
                      </div>
                      <!-- show buttons only for a sample request linked with barrel -->

                      <div
                        class="btn-group"
                        v-if="isBarrelActionsVisible(props.row)"
                      >
                        <!--
                          show buttons if:
                          1. not a new record
                          2. record saved to db i.e ID is number (temp records have id = uuidv4)
                          3. User is reviewer and barrel marked as Selected
                          4. Barrel selected and linked to the current sample request

                        -->

                        <b-button
                          v-if="
                            !isNewRecord(props.row) &&
                              props.row.Type === 'Barrel' &&
                              Number.isInteger(+props.row.ID) &&
                              props.row.Status === 'Selected'
                          "
                          class="btn btn-success btn-sm"
                          title="Mark barrel as sold"
                          @click="sellBarrel(props.row)"
                        >
                          <font-awesome-icon
                            icon="dollar-sign"
                            class="ml-1 mr-1"
                          />
                        </b-button>

                        <b-button
                          v-if="
                            !isNewRecord(props.row) &&
                              props.row.Type === 'Barrel' &&
                              Number.isInteger(+props.row.ID) &&
                              props.row.Status === 'Selected'
                          "
                          class="btn btn-warning btn-sm"
                          title="Deselect barrel"
                          @click="deselectBarrel(props.row)"
                        >
                          <font-awesome-icon icon="undo" />
                        </b-button>
                      </div>

                      <b-button
                        v-if="
                          !isNewRecord(props.row) &&
                            (data.isReviewer || !data.reviewed) &&
                            isBarrelActionsVisible(props.row)
                        "
                        class="btn btn-danger btn-sm"
                        @click="deleteItem(props.row.ID)"
                      >
                        <font-awesome-icon icon="trash" />
                      </b-button>
                    </div>
                  </v-client-table>
                </b-overlay>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="2">
                <h5>Review details</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.reviewed_by.id"
                  :value="data.reviewed_by"
                  :label="controls.reviewed_by.label"
                  :readonly="controls.reviewed_by.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.date_reviewed.id"
                  :value="data.date_reviewed"
                  :label="controls.date_reviewed.label"
                  :readonly="controls.date_reviewed.readonly"
                  :mode="mode"
                  :required="false"
                />
              </b-col>
              <b-col class="d-flex align-items-center">
                <!--  -->
                <b-button
                  v-if="
                    !(mode === this.$constants.FORM_MODE.CREATE) &&
                      !data.reviewed &&
                      data.isReviewer
                  "
                  variant="warning"
                  class="m-1"
                  @click="setReviewed()"
                >
                  <font-awesome-icon icon="signature" /> Mark as Reviewed
                </b-button>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="2">
                <h5>Shipping details</h5>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.ship_name.id"
                  :value="data.ship_name"
                  :label="controls.ship_name.label"
                  :readonly="controls.ship_name.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.ship_phone.id"
                  :value="data.ship_phone"
                  :label="controls.ship_phone.label"
                  :readonly="controls.ship_phone.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.delivery_type.id"
                  :value="data.delivery_type"
                  :loading="controls.delivery_type.loading"
                  :label="controls.delivery_type.label"
                  :readonly="controls.delivery_type.readonly"
                  :options="controls.delivery_type.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="false"
                />
              </b-col>
              <b-col class="d-flex align-items-center">
                <!--

                data.reviewed:{{ data.reviewed }}; data.delivery_type.label:{{
                  data.delivery_type.label
                }}; data.shipped:{{ data.shipped }}; data.date_shipped:{{
                  data.date_shipped
                }}; data.tracking:{{ data.tracking }};
-->
                <b-button
                  v-if="
                    data.reviewed &&
                      data.delivery_type.label === 'Ship' &&
                      !data.shipped
                  "
                  :disabled="
                    !(data.date_shipped !== '' && data.tracking !== '')
                  "
                  variant="primary"
                  class="m-1"
                  @click="setShipped()"
                >
                  <font-awesome-icon v-if="!isLoading" icon="shipping-fast" />
                  <b-spinner v-if="isLoading" small type="grow" />
                  Mark as Shipped
                </b-button>

                <b-button
                  v-if="
                    data.reviewed &&
                      data.delivery_type.label === 'Pick Up' &&
                      !data.pickedup
                  "
                  :disabled="!(data.date_pickup !== '')"
                  variant="primary"
                  class="m-1 "
                  @click="setPickedUp()"
                >
                  <font-awesome-icon v-if="!isLoading" icon="box-open" />
                  <b-spinner v-if="isLoading" small type="grow" />
                  Mark as Picked up
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.shipped_by.id"
                  :value="data.shipped_by"
                  :loading="controls.shipped_by.loading"
                  :label="controls.shipped_by.label"
                  :readonly="controls.shipped_by.readonly"
                  :options="controls.shipped_by.options"
                  :allow-empty="false"
                  :multiple="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="false"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.tracking.id"
                  :value="data.tracking"
                  :label="controls.tracking.label"
                  :readonly="controls.tracking.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-date-picker
                  v-if="data.delivery_type.label === 'Ship'"
                  :id="controls.date_shipped.id"
                  :value-single="data.date_shipped"
                  :label="controls.date_shipped.label"
                  :readonly="controls.date_shipped.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateDateField"
                />
                <inline-date-picker
                  v-if="data.delivery_type.label === 'Pick Up'"
                  :id="controls.date_pickup.id"
                  :value-single="data.date_pickup"
                  :label="controls.date_pickup.label"
                  :readonly="controls.date_pickup.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateDateField"
                />
              </b-col>
            </b-row>

            <hr />
            <b-row>
              <b-col cols="2">
                <h5>Shipping address</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.street.id"
                  :value="data.street"
                  :label="controls.street.label"
                  :readonly="controls.street.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.country.id"
                  :value="data.country"
                  :label="controls.country.label"
                  :readonly="controls.country.readonly"
                  :options="controls.country.options"
                  :allow-empty="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="false"
                />
              </b-col>

              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.state.id"
                  :value="data.state"
                  :label="controls.state.label"
                  :readonly="controls.state.readonly"
                  :taggable="true"
                  :options="controls.state.options"
                  :allow-empty="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="false"
                />
              </b-col>

              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.city.id"
                  :value="data.city"
                  :label="controls.city.label"
                  :readonly="controls.city.readonly"
                  :taggable="true"
                  :options="controls.city.options"
                  :allow-empty="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="false"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.zip.id"
                  :value="data.zip"
                  :label="controls.zip.label"
                  :readonly="controls.zip.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
          </form>
        </b-tab>
        <b-tab
          :title="controls.tabs.files.title"
          v-if="mode !== this.$constants.FORM_MODE.CREATE"
        >
          <files-container
            :module-id="$route.meta.module.id"
            :entity-id="id"
            @loaded="onFileContainerLoad"
          />
        </b-tab>
        <b-tab
          :title="controls.tabs.projects.title"
          v-if="mode !== this.$constants.FORM_MODE.CREATE"
        >
          <projects-table
            ref="projects-table"
            :show-add-button="false"
            :projects="relatedProjects"
            @loaded="onProjectsLoad"
          />
        </b-tab>
      </b-tabs>

      <form-submission-actions
        v-if="action !== 'preview'"
        :mode="mode"
        :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
        :buttons-visibility="{
          previous: $customTable.getPrevKey($route.meta.module, id),
          next: $customTable.getNextKey($route.meta.module, id)
        }"
        @previous-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getPrevKey($route.meta.module, id)
            }
          })
        "
        @next-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getNextKey($route.meta.module, id)
            }
          })
        "
        @save="
          save('tabular').then(response =>
            response ? $router.push({ name: 'Sample requests' }) : false
          )
        "
        @save-and-view="
          save('view').then(response =>
            response
              ? $router.push({
                  name: 'Sample request submission',
                  params: { action: 'view', id: response }
                })
              : false
          )
        "
        @edit="
          $router.push({
            name: 'Sample request submission',
            params: { action: 'edit', id: id }
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
      />
    </b-card>

    <b-modal
      ref="contact-preview-modal"
      centered
      scrollable
      size="lg"
      :title="contactPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh;">
        <ContactSubmissionForm :id="contactPreviewModal.id" action="preview" />
      </b-container>
      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openContactInNewWindow(contactPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>

          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeContactPreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="account-preview-modal"
      centered
      scrollable
      size="lg"
      :title="accountPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh;">
        <AccountSubmissionForm :id="accountPreviewModal.id" action="preview" />
      </b-container>

      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openAccountInNewWindow(accountPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeAccountPreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="pos-task-preview-modal"
      centered
      scrollable
      size="lg"
      :title="posTaskPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh;">
        <TaskSubmissionForm :id="posTaskPreviewModal.id" action="view" />
      </b-container>

      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openTaskInNewWindow(posTaskPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeTaskPreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="request-sample-modal"
      centered
      hide-footer
      size="md"
      title="New sample request"
    >
      <b-container fluid>
        <b-row>
          <b-col lg="6" md="6">
            <inline-input
              id="new-sample-request-sender"
              :value="newSampleRequestMail.sender_name"
              :hide-label="false"
              label="Sender"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
              :readonly="true"
            />
          </b-col>

          <b-col lg="6" md="6">
            <inline-input
              ref="new-sample-request-recipient"
              id="new-sample-request-recipient"
              :value="newSampleRequestMail.recipient_email"
              :hide-label="false"
              label="Recipient"
              @changed="onNewSampleRequestRecipientChanged"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12" md="12">
            <inline-input
              ref="new-sample-request-message"
              id="new-sample-request-message"
              :value="newSampleRequestMail.message"
              @changed="onNewSampleRequestMessageChanged"
              :hide-label="false"
              label="Message"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
              :is-text-area="true"
            />
          </b-col>
        </b-row>
      </b-container>
      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="sendNewSampleRequestMail()"
          :disabled="newSampleRequestMail.isLoading"
        >
          <b-spinner v-if="newSampleRequestMail.isLoading" small type="grow" />
          <font-awesome-icon
            v-if="!newSampleRequestMail.isLoading"
            icon="paper-plane"
          />
          Send mail
        </b-button>

        <b-button
          variant="outline-dark"
          class="m-1"
          @click="closeNewSampleRequestModal()"
        >
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";
import InlineDatePicker from "@/components/InlineDatePicker";
import InlineSwitch from "@/components/InlineSwitch";

import AccountSubmissionForm from "@/views/RelationshipManagement/AccountSubmissionForm";
import ContactSubmissionForm from "@/views/RelationshipManagement/ContactSubmissionForm";
import TaskSubmissionForm from "@/views/ProjectManagement/TaskSubmissionForm";

import FilesContainer from "@/components/FilesContainer";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import ProjectsTable from "@/views/ProjectManagement/ProjectsTable";

//import controls from './controls'
//import data from './data'
//import dataTable from './dataTable'
import methods from "./methods";

import { mapState } from "vuex";

export default {
  name: "SampleRequestForm",
  props: {
    id: {
      type: [String, Number],
      default: undefined
    },
    action: {
      type: String,
      default: undefined
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,
    FilesContainer,
    AccountSubmissionForm,
    ContactSubmissionForm,
    Multiselect,
    FormSubmissionActions,
    InlineSwitch,
    TaskSubmissionForm,
    ProjectsTable
  },
  data: function() {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      isModified: false,
      mode: undefined,
      newSampleRequestMail: {
        sender_name: "",
        recipient_email: "",
        message: "",
        isLoading: false
      },
      data: {
        requested_by: {
          id: "",
          label: ""
        },
        street: "",
        country: {
          id: "",
          label: ""
        },
        state: {
          id: "",
          label: ""
        },
        city: {
          id: "",
          label: ""
        },
        delivery_type: {
          id: "",
          label: ""
        },
        zip: "",
        reason: "",
        notes: "",
        date_needed: "",
        account: "",
        distributor: "",
        contact: "",
        ship_name: "",
        ship_phone: "",
        created: "",
        modified: "",
        reviewed: false,
        reviewed_by: "",
        date_reviewed: "",
        pickedup: "",
        date_pickup: "",
        shipped: "",
        date_shipped: "",
        shipped_by: {
          id: "",
          label: ""
        },
        tracking: "",
        isReviewer: false,
        barrel_sample: false,
        pos_task: ""
      },
      posTaskPreviewModal: {
        id: "",
        title: ""
      },
      accountPreviewModal: {
        id: "",
        title: ""
      },
      contactPreviewModal: {
        id: "",
        title: ""
      },
      controls: {
        tabs: {
          files: {
            title: "Files"
          },
          projects: {
            title: "Projects"
          }
        },
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        requested_by: {
          id: "select:requested_by",
          label: "Requested by",
          changed: false,
          readonly: false,
          options: []
        },
        date_needed: {
          id: "datepicker:date_needed",
          label: "Date needed",
          changed: false,
          readonly: false
        },
        ship_name: {
          id: "input:ship_name",
          label: "Name",
          changed: false,
          readonly: false
        },
        ship_phone: {
          id: "input:ship_phone",
          label: "Phone",
          changed: false,
          readonly: false
        },
        account: {
          id: "select:account",
          label: "Account",
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        distributor: {
          id: "select:distributor",
          label: "Distributor",
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        contact: {
          id: "select:contact",
          label: "Contact",
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        pos_task: {
          id: "select:pos_task",
          label: "POS Task",
          options: [],
          changed: false,
          readonly: true,
          loading: false
        },

        street: {
          id: "input:street",
          label: "Street",
          changed: false,
          readonly: false
        },
        country: {
          id: "select:country",
          label: "Country",
          options: [],
          changed: false,
          readonly: false
        },
        state: {
          id: "select:state",
          label: "State",
          options: [],
          changed: false,
          readonly: false
        },
        city: {
          id: "select:city",
          label: "City",
          options: [],
          changed: false,
          readonly: false
        },
        zip: {
          id: "input:zip",
          label: "Zip",
          changed: false,
          readonly: false
        },
        reason: {
          id: "input:reason",
          label: "Reason",
          changed: false,
          readonly: false
        },
        delivery_type: {
          id: "select:delivery_type",
          label: "Delivery Type",
          options: [
            {
              id: "0",
              label: "Pick Up"
            },
            {
              id: "1",
              label: "Ship"
            }
          ],
          changed: false,
          readonly: false
        },
        notes: {
          id: "input:notes",
          label: "Notes",
          changed: false,
          readonly: false
        },
        barrel_sample: {
          id: "switch:barrel_sample",
          label: "Barrel sample request",
          changed: false,
          readonly: false
        },

        shipped_by: {
          id: "select:shipped_by",
          label: "Shipped by",
          changed: false,
          readonly: false,
          options: []
        },
        date_pickup: {
          id: "datepicker:date_pickup",
          label: "Date pickup",
          changed: false,
          readonly: false
        },
        date_shipped: {
          id: "datepicker:date_shipped",
          label: "Date shipped",
          changed: false,
          readonly: false
        },
        tracking: {
          id: "input:tracking",
          label: "Tracking",
          changed: false,
          readonly: false
        },
        reviewed_by: {
          id: "input:reviewed_by",
          label: "Reviewed By",
          changed: false,
          readonly: true
        },

        date_reviewed: {
          id: "input:date_reviewed",
          label: "Date reviewed",
          changed: false,
          readonly: true
        },
        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: true
        },
        modified: {
          id: "input:modified",
          label: "Modified",
          changed: false,
          readonly: true
        }
      },
      relatedProjects: [],
      dataTable: {
        isLoading: false,
        options: {
          uniqueKey: "ID",
          filterByColumn: false,
          showChildRowToggler: false,
          filterable: [],
          editableColumns: ["Item", "Quantity", "Status"],
          perPage: 10,
          perPageValues: [],
          saveState: true,
          storage: "local"
        },
        columns: [
          "Item",
          "Notes",
          "Status",
          "Barrel sample request",
          "Type",
          "Quantity",
          "Project",
          "Actions"
        ],
        formatColumns: [
          {
            name: "Quantity",
            style: {
              style: "decimal"
            }
          }
        ],
        dataSet: [],
        isInserting: false,
        dropdowns: [
          {
            name: "POS",
            options: [],
            value: [],
            multiple: false,
            allowEmpty: false
          },
          {
            name: "Sample",
            options: [],
            value: [],
            multiple: false,
            allowEmpty: false
          },
          {
            name: "Barrel",
            options: [],
            value: [],
            multiple: false,
            allowEmpty: false
          }
        ]
      },
      districts: []
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),

  created() {
    this.initialize();
  },
  mounted: function() {},
  async beforeRouteLeave(to, from, next) {
    if (!this.isModified || this.mode !== this.$constants.FORM_MODE.EDIT) {
      next();
      return;
    }

    const answer = await this.$form.showConfirmation(
      "You have unsaved changes! Do you really want to leave?"
    );

    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  methods: methods,
  watch: {
    "data.country": function(newVal) {
      if (newVal.id) {
        this.controls.state.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.country === newVal.id),
          "state",
          "state",
          "id",
          "label"
        );
      }
    },
    "data.state": function(newVal) {
      if (newVal.id) {
        this.controls.city.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.state === newVal.id),
          "city",
          "city",
          "id",
          "label"
        );
      }
    }
  }
};
</script>

<style scoped>
::v-deep .table-responsive {
  overflow: visible;
}

::v-deep table {
  overflow: visible !important;
}

::v-deep table .multiselect {
  width: 25em;
}

/*account preview modal resize */
.modal-lg {
  max-width: 70vw !important;
  max-height: 70vh !important;
}
</style>
