import Vue from 'vue'

import { ITEM_TYPE } from './constants'

import { mapActions } from 'vuex'

export default {
  ...mapActions('profile', ['fetchProfile']),
  initialize () {
    let self = this

    if (this.action == 'create') {
      this.mode = this.$constants.FORM_MODE.CREATE
    }
    if (this.action == 'edit') {
      this.mode = this.$constants.FORM_MODE.EDIT
    }
    if (this.action == 'view') {
      this.mode = this.$constants.FORM_MODE.VIEW
    }

    this.data.id = this.id

    this.loadDictionaries()

    if (this.mode == this.$constants.FORM_MODE.CREATE) {
      this.data.date_needed = ''
      this.data.date_shipped = ''
      this.data.date_pickup = ''
    }

    if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
      this.fetchSampleRequest(this.id).then(() => {
        self.controls.requested_by.readonly = true
      })
    }

    if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
      this.fetchDataTable(this.id)
    }
  },

  async findProjects (projects) {
    if (!projects.length) return

    let payload = {},
      rules = []

    let subject = {
      field: 'project_id',
      type: 'string',
      logic: 'Any',
      rules: []
    }

    //add passed projects ids to the expression
    projects.forEach(project_id => {
      subject.rules.push({
        logicalOperator: 'Equals',
        selectedValues: [],
        selectedValue: project_id
      })
    })

    rules.push(subject)

    payload.expression = JSON.stringify(rules)

    await this.$refs['projects-table'].getData(payload)
  },
  onProjectsLoad (count) {
    if (count > 0) this.controls.tabs.projects.title = `Projects (${count})`
  },
  isBarrelActionsVisible (row) {
    return +row.type_id == 3 && +row['Barrel sample request'] == row.request_id
  },
  addAccount: function () {
    let routeData = this.$router.resolve({
      name: 'Account submission',
      params: {
        action: 'create'
      }
    })
    window.open(routeData.href, '_blank')
  },
  addContact: function () {
    let routeData = this.$router.resolve({
      name: 'Contact submission',
      params: {
        action: 'create'
      }
    })
    window.open(routeData.href, '_blank')
  },

  async sellBarrel (row) {
    let self = this

    let confirm = await this.$form.showConfirmation(
      `Barrel '${row.Item}' will be marked as sold. Do you want to proceed?`
    )

    if (!confirm) return

    let data = {
      id: row.ID,
      item_id: this.rowDropdown(row, row.Type).value.id,
      sell: true
    }

    this.dataTable.isLoading = true

    this.$api
      .put(`sample-requests/${this.id}/items/${this.data.id}`, data)
      .then(response => {
        self.$form.makeToastInfo(response.message)

        //update form to show linked POS task
        self.fetchSampleRequest(this.id).then(() => {
          self.controls.requested_by.readonly = true
        })

        self.fetchDataTable()
      })
      .catch(error => {
        self.dataTable.isLoading = false

        self.$form.makeToastError(error.message)
      })
  },
  async deselectBarrel (row) {
    let self = this

    let confirm = await this.$form.showConfirmation(
      `Barrel '${row.Item}' will be set as available. Do you want to proceed?`
    )

    if (!confirm) return

    let data = {
      id: row.ID,
      item_id: this.rowDropdown(row, row.Type).value.id,
      deselect: true
    }

    this.dataTable.isLoading = true

    this.$api
      .put(`sample-requests/${this.id}/items/${this.data.id}`, data)
      .then(response => {
        self.$form.makeToastInfo(response.message)

        self.fetchDataTable()
      })
      .catch(error => {
        this.dataTable.isLoading = false

        self.$form.makeToastError(error.message)
      })
  },
  async setShipped () {
    let self = this

    let confirm = await this.$form.showConfirmation(
      'Sample request will be marked as shipped. Do you want to proceed?'
    )

    if (!confirm) return

    this.isModified = true

    this.data.shipped = true

    if (this.mode == this.$constants.FORMCONTROLMODE.VIEW) {
      this.$api
        .put(`sample-requests/${this.data.id}/shipped`)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          this.initialize()
        })
        .catch(error => {
          self.$form.makeToastError(error.message)
        })
    }
  },
  async setPickedUp () {
    let self = this

    let confirm = await this.$form.showConfirmation(
      'Sample request will be marked as picked up. Do you want to proceed?'
    )

    if (!confirm) return

    this.isModified = true

    this.data.pickedup = true

    if (this.mode == this.$constants.FORMCONTROLMODE.VIEW) {
      this.isLoading = true

      this.$api
        .put(`sample-requests/${this.data.id}/pickedup`)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          this.initialize()
        })
        .catch(error => {
          self.$form.makeToastError(error.message)
        })
    }
  },

  async setReviewed () {
    let self = this

    let confirm = await this.$form.showConfirmation(
      (this.data.new_sample_email_sent && this.data.linked_barrels_count == 0
        ? 'Please be aware this sample request still oustanding! '
        : '') +
        'Sample request will be marked as reviewed and will be put into read only mode. Do you want to proceed?'
    )

    if (!confirm) return

    this.isModified = true

    this.data.reviewed = true

    if (this.mode == this.$constants.FORMCONTROLMODE.VIEW) {
      this.isLoading = true

      this.$api
        .put(`sample-requests/${this.data.id}/reviewed`)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          this.initialize()
        })
        .catch(error => {
          self.$form.makeToastError(error.message)
        })
    }
  },
  onContactLinkClicked (e) {
    this.contactPreviewModal.id = e.id
    this.contactPreviewModal.title = `Contact ${e.label}`

    this.$refs['contact-preview-modal'].show()
  },
  openContactPreview () {
    this.$refs['contact-preview-modal'].show()
  },
  closeContactPreview: function () {
    this.$refs['contact-preview-modal'].hide()
  },
  openContactInNewWindow (contact_id) {
    let routeData = this.$router.resolve({
      name: 'Contact submission',
      params: {
        id: contact_id,
        action: 'view'
      }
    })
    window.open(routeData.href, '_blank')
  },

  onDistributorLinkClicked (e) {
    this.accountPreviewModal.id = e.id
    this.accountPreviewModal.title = `Account ${e.label}`
    this.$refs['account-preview-modal'].show()
  },
  onAccountLinkClicked (e) {
    this.accountPreviewModal.id = e.id
    this.accountPreviewModal.title = `Account ${e.label}`

    this.$refs['account-preview-modal'].show()
  },
  openAccountPreview () {
    this.$refs['account-preview-modal'].show()
  },
  closeAccountPreview: function () {
    this.$refs['account-preview-modal'].hide()
  },
  openAccountInNewWindow (account_id) {
    let routeData = this.$router.resolve({
      name: 'Account submission',
      params: {
        id: account_id,
        action: 'view'
      }
    })
    window.open(routeData.href, '_blank')
  },
  onPosTaskLinkClicked (e) {
    this.posTaskPreviewModal.id = e.id
    this.posTaskPreviewModal.title = `Task ${e.label}`

    this.$refs['pos-task-preview-modal'].show()
  },
  openTaskPreview () {
    this.$refs['pos-task-preview-modal'].show()
  },
  closeTaskPreview: function () {
    this.$refs['pos-task-preview-modal'].hide()
  },
  openTaskInNewWindow (task_id) {
    let routeData = this.$router.resolve({
      name: 'Task submission',
      params: {
        id: task_id,
        action: 'view'
      }
    })
    window.open(routeData.href, '_blank')
  },
  async updateAddressFields (field, value) {
    this.data.country = {
      id: '',
      label: ''
    }
    this.data.state = {
      id: '',
      label: ''
    }
    this.data.city = {
      id: '',
      label: ''
    }

    this.data.street = ''
    this.data.zip = ''

    if (!value) {
      return
    }

    let id
    if (field == 'account') id = this.data.account.id
    if (field == 'distributor') id = this.data.distributor.id

    this.isLoading = true

    let response = await this.$api.get(`accounts/${id}`)

    this.isLoading = false

    this.data.country = {
      id: response['Country'],
      label: response['Country']
    }

    this.data.state = {
      id: response['State'],
      label: response['State']
    }
    this.data.city = {
      id: response['City'],
      label: response['City']
    }

    this.data.street = response['Street']
    this.data.zip = response['Postal Code']

    let payload = {
      country: this.data.country,
      state: this.data.state,
      city: this.data.city,
      street: this.data.street,
      zip: this.data.zip
    }

    let self = this

    if (this.mode == this.$constants.FORMCONTROLMODE.VIEW)
      this.$api
        .put(`sample-requests/${this.data.id}`, payload)
        .then(response => {
          self.$form.makeToastInfo(`address: ${response.message}`)

          //self.controls[field].changed = false;

          //this.initialize();
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(`${response.message}`)
        })
  },
  onFileContainerLoad (count) {
    if (count > 0) this.controls.tabs.files.title = `Files (${count})`
  },
  onChangeSelect (_value, _id) {
    let id = _id.split(':')[0]
    let column = _id.split(':')[1]

    let idx = this.dataTable.dataSet.findIndex(item => item.ID == id)

    let item = this.dataTable.dataSet[idx]

    let dropdown = item.dropdowns.find(n => n.name == item.Type)

    dropdown.value = _value

    if (this.dropdown(item.Type).multiple) {
      item[column] = dropdown.value.map(e => e.label).join(', ')
    } else {
      item[column] = dropdown.value.label
    }

    if (item.Type == 'Barrel') {
      item['Notes'] = dropdown.value.notes
    }

    Vue.set(this.dataTable.dataSet, idx, item)
  },
  saveNewRecord () {
    let self = this

    let newrecord = this.dataTable.dataSet.find(
      item => item.ID == this.$constants.CUSTOM_TABLE.NEW_ROW_ID
    )

    if (this.rowDropdown(newrecord, newrecord.Type).value.length == 0) {
      this.$form.makeToastError('Please select item')
      return
    }

    if (parseFloat(newrecord['Quantity']) <= 0) {
      this.$form.makeToastError('Please enter quantity')
      return
    }

    //hide editable cells

    self.$refs.dataTable.$children[0].editing = []
    newrecord.isInserting = false
    self.dataTable.isInserting = false

    //newrecord.ID = this.$helpers.uuidv4(); //replace ID='new' with a random id until data will not be stored to the database

    //newrecord['ID'] = '-' + (self.dataTable.dataSet.length + 1).toString()
    newrecord['ID'] = this.$helpers.uuidv4()

    //disable selected barrel in the dropdown
    let selectedBarrelOption = self
      .dropdown('Barrel')
      .options.find(
        b => b.id == this.rowDropdown(newrecord, newrecord.Type).value.id
      )
    if (selectedBarrelOption) selectedBarrelOption.$isDisabled = true

    if (this.mode == this.$constants.FORM_MODE.VIEW) {
      //prepare post request
      let post_data = {
        request_id: this.id
      }

      post_data.item_id = this.rowDropdown(newrecord, newrecord.Type).value.id
      post_data.type_id = ITEM_TYPE[newrecord.Type]
      post_data.quantity = newrecord['Quantity']

      this.dataTable.isLoading = true

      this.$api
        .post(`sample-requests/${this.id}/items`, post_data)
        .then(response => {
          newrecord.isInserting = false

          self.dataTable.isInserting = false

          self.$form.makeToastInfo(response.message)

          self.fetchDataTable()
        })
        .catch(function (error) {
          self.$form.makeToastError(error.message)

          newrecord.isInserting = false

          self.dataTable.isInserting = false

          self.revertNewRecord()

          self.fetchDataTable()
        })
    }
  },
  revertNewRecord () {
    this.dataTable.dataSet.splice(0, 1)

    this.$refs.dataTable.$children[0].editing = []

    this.dataTable.isInserting = false
  },

  async deleteItem (id) {
    let row = this.dataTable.dataSet.find(item => item.ID == id)

    let confirm = await this.$form.showConfirmation(
      `Item '${row['Item']}' will be removed from this request. Selected barrels will be deselected. Do you want to proceed?`
    )

    if (!confirm) return

    this.isModified = true

    let self = this

    this.dataTable.dataSet = this.dataTable.dataSet.filter(i => i.ID !== row.ID)

    //set removed barrel as enabled again
    if (row.Type == 'Barrel') {
      let selectedBarrelOption = self
        .dropdown('Barrel')
        .options.find(b => b.id == this.rowDropdown(row, row.Type).value.id)
      if (selectedBarrelOption) selectedBarrelOption.$isDisabled = false
    }

    if (this.mode == this.$constants.FORM_MODE.VIEW) {
      this.dataTable.isLoading = true

      this.$api
        .delete(`sample-requests/${this.id}/items/${row.ID}`, row)
        .then(() => {
          self.$form.makeToastInfo('Item removed')

          self.fetchDataTable()
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(response.data.message)

          self.fetchDataTable()
        })
    }
  },
  onInlineTableUpdate (payload) {
    let self = this

    let data = {
      id: payload.row['ID']
    }

    if (payload.column == 'Item') {
      data.item_id = this.rowDropdown(payload.row, payload.row.Type).value.id
    }

    if (payload.column == 'Quantity') {
      data.quantity = payload['newVal']
    }

    // self.onColumnUpdate(data.product_id, payload.column, payload["newVal"])

    if (this.mode == this.$constants.FORM_MODE.VIEW) {
      this.$api
        .put(`sample-requests/${this.id}/items/${payload.row['ID']}`, data)
        .then(response => {
          self.fetchDataTable()

          self.$form.makeToastInfo(response.message)
        })
        .catch(function (error) {
          self.$form.makeToastError(error.message)

          self.fetchDataTable()
        })
    }
  },
  onColumnUpdate (id, column, value) {
    let idx = this.dataTable.dataSet.findIndex(item => item['ID'] == id)

    let item = this.dataTable.dataSet[idx]

    item[column] = value

    Vue.set(this.dataTable.dataSet, idx, item)
  },

  validateCell (field, value) {
    if (field == 'Name' && value.trim() == '') {
      this.$form.makeToastError('Please select item')
      return false
    }

    if (field == 'Quantity' && parseFloat(value) <= 0) {
      this.$form.makeToastError('Please select quantity')
      return false
    }

    return true
  },
  isNewRecord (row) {
    if (!row) return false

    return row['ID'] == this.$constants.CUSTOM_TABLE.NEW_ROW_ID
  },

  addItem (type) {
    let newitem = {
      ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
      Item: '',
      Type: type,
      Quantity: '1',
      dropdowns: [
        {
          name: type,
          value: []
        }
      ]
    }

    this.isModified = true

    this.dataTable.isInserting = true

    this.dataTable.dataSet.unshift(newitem)

    Vue.set(this.dataTable.dataSet, 0, newitem)

    this.$nextTick(() => {
      if (!this.$refs.dataTable) return

      var _this2 = this.$refs.dataTable.$children[0]

      _this2.editing.push({
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        column: 'Item',
        originalValue: ''
      })
      _this2.editing.push({
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        column: 'Quantity',
        originalValue: ''
      })
    })
  },
  openNewSampleRequestModal () {
    //this.newSampleRequestMail.sender = this.profile.data.email1
    this.newSampleRequestMail.sender_name = this.profile.data.full_name
    this.newSampleRequestMail.recipient_email =
      'barrelsamples@kovaldistillery.com'

    this.$refs['request-sample-modal'].show()
  },
  closeNewSampleRequestModal () {
    this.$refs['request-sample-modal'].hide()
  },
  onNewSampleRequestRecipientChanged () {
    this.newSampleRequestMail.recipient_email = this.$refs[
      'new-sample-request-recipient'
    ].getValue()
  },

  onNewSampleRequestMessageChanged () {
    this.newSampleRequestMail.message = this.$refs[
      'new-sample-request-message'
    ].getValue()
  },

  sendNewSampleRequestMail () {
    if (!this.newSampleRequestMail.message) {
      this.$form.makeToastError('Message is empty!')

      return
    }

    this.newSampleRequestMail.request_id = this.id
    this.newSampleRequestMail.isLoading = true

    this.$api
      .post('sample-requests/new-sample-mail', this.newSampleRequestMail)
      .then(() => {
        this.$form.makeToastInfo('E-mail sent!')

        this.newSampleRequestMail.message = ''

        this.closeNewSampleRequestModal()

        this.fetchSampleRequest(this.id)
      })
      .catch(error => {
        this.$form.makeToastError(error.message)
      })
      .finally(() => {
        this.newSampleRequestMail.isLoading = false
      })
  },
  dropdown (_name) {
    return this.dataTable.dropdowns.find(n => n.name == _name)
  },
  rowDropdown (_row, _name) {
    return _row.dropdowns.find(d => d.name == _name)
  },
  onAccountSearch (payload) {
    let self = this

    self.controls.account.loading = true

    this.$api
      .post('dictionaries/accounts', {
        query: payload.query,
        starts_with: payload.startsWith
      })
      .then(response => {
        self.controls.account.loading = false

        self.controls.account.options = response.map(u => ({
          id: u.id,
          label: u.name
        }))
      })
  },
  async loadDictionaries () {
    let self = this

    this.$api.get('users').then(response => {
      self.controls.requested_by.options = response.map(u => ({
        id: u.id,
        label: u.full_name
      }))

      self.controls.shipped_by.options = response.map(u => ({
        id: u.id,
        label: u.full_name
      }))

      if (this.mode == this.$constants.FORM_MODE.CREATE) {
        this.data.requested_by = {
          id: this.profile.data.id.toString(),
          label:
            this.profile.data.first_name + ' ' + this.profile.data.last_name
        }
      }
    })

    self.controls.distributor.loading = true
    this.$api.get('dictionaries/accounts/distributors').then(response => {
      self.controls.distributor.loading = false

      self.controls.distributor.options = self.$helpers.getDistinctArray(
        response,
        'id',
        'name',
        'id',
        'label'
      )
    })

    //this.updateContactsDropdown()

    this.$api
      .post('dictionaries/countries/mapped', {
        ignore_restrictions: true
      })
      .then(response => {
        self.districts = response
        self.controls.country.options = self.$helpers.getDistinctArray(
          response,
          'country',
          'country',
          'id',
          'label'
        )
        self.controls.state.options = self.$helpers.getDistinctArray(
          response.filter(s => s.country == self.data.country.id),
          'state',
          'state',
          'id',
          'label'
        )
        self.controls.city.options = self.$helpers.getDistinctArray(
          response.filter(s => s.state == self.data.state.id),
          'city',
          'city',
          'id',
          'label'
        )
      })

    this.$api.get('dictionaries/samples').then(response => {
      self.dropdown('Sample').options = response.map(u => ({
        id: u.id,
        label: u.name
      }))
    })

    this.$api.get('dictionaries/pos').then(response => {
      self.dropdown('POS').options = response.map(u => ({
        id: u.id,
        label: u.name
      }))
    })

    /*
                    this.$api.get(`dictionaries/barrels/available`).then(response => {

                        self.dropdown('Barrel').options = response.map(u => ({
                            id: u.barrel_number,
                            label: `${u.finished_product_name} [${u.barrel_number}, ${u.reservation_date}]`
                        }))
                    });
        */
    this.loadAvailableBarrels()
  },
  updateContactsDropdown (relatedTo = []) {
    this.controls.contact.loading = true

    this.$api
      .post('dictionaries/contacts', { related_to: relatedTo.join() })
      .then(response => {
        this.controls.contact.loading = false

        this.controls.contact.options = response.map(item => {
          let label = []
          !item['State'] || label.push(item['State'])
          !item['Email'] || label.push(item['Email'])

          label = `${item['Contact Name']} [${label.join(',')}]`

          return {
            id: item['ID'],
            label: label
          }
        })
      })
  },
  loadAvailableBarrels () {
    let self = this

    this.$api
      .get(`dictionaries/barrels/available/${this.id}`)
      .then(response => {
        self.dropdown('Barrel').options = response.map(u => ({
          id: u.barrel_number,
          label: `${u.finished_product_name} [${u.barrel_number}, ${u.reservation_date}]`,
          notes: u.notes,
          $isDisabled: false
        }))
      })
  },
  onFilter () {
    // this.dataTable.totalRecords = this.$refs.dataTable.data.length;
  },

  edit () {},

  save (_mode) {
    if (!this.$form.testForm(this)) {
      this.$form.makeToastError('Form contains errors')
      return Promise.resolve(false)
    }

    this.saveInProgress = _mode == 'tabular'
    this.saveAndViewInProgress = _mode == 'view'

    let self = this
    let method = ''

    let url = 'sample-requests'
    if (this.mode == this.$constants.FORM_MODE.CREATE) {
      method = 'put'
      url = '/sample-requests'
    }
    if (this.mode == this.$constants.FORM_MODE.EDIT) {
      method = 'put'
      url = `/sample-requests/${this.data.id}`
    }

    this.dataTable.dataSet.forEach(p => {
      p.post_data = {
        request_id: this.id
      }
      p.post_data.item_id = self.rowDropdown(p, p.Type).value.id
      p.post_data.type_id = ITEM_TYPE[p.Type]
      p.post_data.quantity = p['Quantity']
    })

    this.data.items = this.dataTable.dataSet
      .filter(p => p['ID'] !== this.$constants.CUSTOM_TABLE.NEW_ROW_ID)
      .map(i => i.post_data)

    this.isLoading = true

    return this.$api[method](url, this.data)
      .then(response => {
        this.isModified = false

        self.isLoading = false
        self.saveInProgress = false
        self.saveAndViewInProgress = false

        self.$form.makeToastInfo(response.message)

        this.$router.currentRoute.params.id = response.id

        return response.id
      })
      .catch(error => {
        this.isLoading = false
        self.isLoading = false
        self.saveInProgress = false

        //this.$form.makeToastError(error.message);
        this.$form.makeToastError(error.statusText)

        return Promise.resolve(false)
      })
  },
  updateDateField (e) {
    this.updateField(e.id, e.valueSingleAsString)
  },
  updateField (field, value) {
    let self = this

    let params = {}
    params['id'] = this.id
    params[field] = value

    this.isModified = true

    //update local data values - it can be used in Edit action
    self.data[field] = value

    //if control in View mode then update database

    if (this.mode == this.$constants.FORMCONTROLMODE.VIEW)
      this.$api
        .put(`sample-requests/${this.data.id}`, params)
        .then(response => {
          self.$form.makeToastInfo(`${field}: ${response.message}`)

          self.controls[field].changed = false

          // this.initialize();
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(`${field}: ${response.message}`)
        })
  },
  updateSelect (id, value) {
    this.updateField(id, value)

    if (id == 'distributor') {
      this.updateAddressFields(id, value)

      this.updateContactsDropdown([
        value ? value.id : -1,
        this.data.distributor.id
      ])
    }
  },
  fetchDataTable: async function () {
    this.loadAvailableBarrels()

    this.dataTable.isLoading = true
    console.log(`sample-requests/${this.id}/items`)
    let url = `sample-requests/${this.id}/items`

    let response = await this.$api.get(url)
    console.log('response', response)
    this.rawData = response

    this.dataTable.isLoading = false

    this.dataTable.dataSet = this.rawData

    let linkedProjects = []
    //create array of selected values for multiselect input
    this.dataTable.dataSet.forEach(row => {
      row.dropdowns = []

      row.dropdowns.push({
        name: row['Type'],
        value: []
      })

      let val = {
        id: row['item_id'],
        label: row['Item'].trim()
      }

      this.rowDropdown(row, row['Type']).value = val

      //collect linked projects
      if (row['Project ID']) linkedProjects.push(row['Project ID'].trim())
    })

    if (linkedProjects.length) this.findProjects(linkedProjects)
  },
  async fetchSampleRequest (id) {
    let self = this

    this.isLoading = true

    return this.$api
      .get(`/sample-requests/${id}`)
      .then(response => {
        this.isLoading = false

        if (self.$_.isEmpty(response)) return

        self.data.id = self.id

        self.data.requested_by = {
          id: response['requested_by_id'],
          label: response['Requested By']
        }

        this.data.country = {
          id: response['Ship Country'],
          label: response['Ship Country']
        }
        this.data.state = {
          id: response['Ship State'],
          label: response['Ship State']
        }
        this.data.city = {
          id: response['Ship City'],
          label: response['Ship City']
        }

        this.data.street = response['Ship Street']
        this.data.zip = response['Ship Zip']
        this.data.created = response['Created']
        this.data.modified = response['Modified']

        this.data.ship_name = response['Ship Name']
        this.data.ship_phone = response['Ship Phone']
        this.data.notes = response['Notes']
        this.data.reason = response['Reason']
        this.data.date_needed = response['Date Needed']
        this.data.barrel_sample =
          response['Barrel Sample'] == 'Yes' ? true : false

        this.data.delivery_type = this.controls.delivery_type.options.find(
          o => o.id == response['delivery_type_id']
        )

        this.data.account = {
          id: response['account_id'],
          label: response['Account Name'],
          url:
            self.$client_url +
            '/#/relationship-management/account/view/' +
            response['account_id']
        }
        this.data.distributor = {
          id: response['distributor_id'],
          label: response['Distributor Name'],
          url:
            self.$client_url +
            '/#/relationship-management/account/view/' +
            response['distributor_id']
        }
        this.data.contact = {
          id: response['contact_id'],
          label: response['Contact Name'],
          url:
            self.$client_url +
            '/#/relationship-management/contact/view/' +
            response['contact_id']
        }
        this.data.pos_task = {
          id: response['pos_task_id'],
          label: response['POS Task'],
          url:
            self.$client_url +
            '/#/project-management/task/view/' +
            response['pos_task_id']
        }

        this.updateContactsDropdown([
          response['account_id'],
          response['distributor_id']
        ])

        this.data.reviewed = response['reviewed_flag'] == 1

        this.data.reviewed_by = response['Reviewed By']

        this.data.date_reviewed = response['Date Reviewed']

        this.data.pickedup = response['pickedup_flag'] == 1

        this.data.date_pickup = response['Date Pickup']

        this.data.shipped = response['shipped_flag'] == 1

        this.data.shipped_by = {
          id: response['shipped_by_id'],
          label: response['Shipped By']
        }

        this.data.date_shipped = response['Date Shipped']
        this.data.tracking = response['Tracking']

        this.data.new_sample_email_sent = +response['new_sample_email_sent']
        this.data.linked_barrels_count = +response['linked_barrels_count']
        this.data.linked_barrels = response['linked_barrels']

        this.$api.get('sample-requests/reviewer').then(response => {
          self.data.isReviewer = response

          self.updateControlsState()
        })
      })
      .catch(error => {
        this.isLoading = false

        console.log(error)
      })
  },
  updateControlsState () {
    //let setReadonly = this.$form.updateControlsState.bind(this)
    //setReadonly(true)

    if (!this.data.reviewed) {
      this.disableShippingDetails(true)
    } else {
      this.disableShippingDetails(false)
    }

    if (this.data.reviewed && !this.data.isReviewer) {
      this.disableRequestDetails(true)
    }

    if ((this.data.shipped || this.data.pickedup) && !this.data.isReviewer) {
      this.disableShippingDetails(true)
      this.disableShippingAddress(true)
    }
  },
  disableRequestDetails (state) {
    this.controls.requested_by.readonly = state

    this.controls.reason.readonly = state
    this.controls.notes.readonly = state
    this.controls.date_needed.readonly = state
    this.controls.account.readonly = state
    this.controls.distributor.readonly = state
    this.controls.contact.readonly = state
  },
  disableShippingDetails (state) {
    this.controls.date_pickup.readonly = state
    this.controls.date_shipped.readonly = state
    this.controls.shipped_by.readonly = state
    this.controls.tracking.readonly = state
  },
  disableShippingAddress (state) {
    this.controls.street.readonly = state
    this.controls.country.readonly = state
    this.controls.state.readonly = state
    this.controls.city.readonly = state
    this.controls.delivery_type.readonly = state
    this.controls.zip.readonly = state
    this.controls.ship_name.readonly = state
    this.controls.ship_phone.readonly = state
  }
}
