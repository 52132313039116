<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(module || $route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @inline-update="onInlineUpdate"
    >
      <div slot="afterFilter">
        <b-row v-if="showAddButton">
          <b-col>
            <b-button-group>
              <button
                class="btn btn-outline-dark btn"
                type="button"
                @click="addItem('form')"
              >
                <i class="fa fa-toolbar fa-plus "></i> Create
              </button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
          >
            <font-awesome-icon icon="eye" />
          </button>
          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row.ID)"
          >
            <font-awesome-icon icon="trash" />
          </button>

          <button
            :class="[
              props.row['tasks_total']
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleTasks(props.row['ID'])"
          >
            <font-awesome-icon icon="tasks" />
          </button>
          <button
            :class="[
              props.row['FilesCount'] !== '0'
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleFiles(props.row['ID'])"
          >
            <font-awesome-icon icon="download" />
          </button>
        </div>
      </div>

      <span slot-scope="props" slot="child_row">
        <tasks-table
          v-if="
            !dataTable.childRow.showFiles &&
              props.row['ID'] !== $constants.CUSTOM_TABLE.NEW_ROW_ID
          "
          :project-id="props.row['ID']"
          :is-nested-table="true"
          :selectable-rows="true"
          :filter-by-column="false"
        >
        </tasks-table>
        <files-container
          ref="files"
          v-if="dataTable.childRow.showFiles"
          :module-id="$route.meta.module.id"
          :entity-id="props.row['ID']"
        ></files-container>
      </span>
    </table-custom>
  </div>
</template>

<script>
import moment from "moment";
import Vue from "vue";
import TasksTable from "@/views/ProjectManagement/TasksTable.vue";
import FilesContainer from "@/components/FilesContainer";
import TableCustom from "@/components/TableCustom";

export default {
  name: "ProjectsTable",
  props: {
    showAddButton: {
      type: Boolean,
      default: true
    },
    module: {
      type: Object,
      default: undefined
    }
  },
  components: {
    TasksTable,
    FilesContainer,
    TableCustom
  },
  data: function() {
    return {
      isLoading: false,
      rawData: {},

      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterable: [
            "ID",
            "Project Name",
            "Start Date",
            "Finish Date",
            "Progress",
            "Status",
            "Assigned To"
          ],
          columns: [
            "ID",
            "Project Name",
            "Start Date",
            "Finish Date",
            "Progress",
            "Status",
            "Assigned To",
            "Actions"
          ],
          editableColumns: ["Project Name"],

          //showActions: true,
          perPage: 50,
          showCustomActions: true,
          showChildRows: true
        },
        childRow: {
          showTasks: false,
          showFiles: false
        }
      },
      serverFilter: undefined
    };
  },
  computed: {},
  mounted() {},
  methods: {
    toggleFiles(id) {
      this.dataTable.childRow.showTasks = !(this.dataTable.childRow.showFiles = true);

      this.$refs.dataTable.toggleChildRow(id);
    },
    toggleTasks(id) {
      this.dataTable.childRow.showTasks = !(this.dataTable.childRow.showFiles = false);

      this.$refs.dataTable.toggleChildRow(id);
    },

    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
      this.highlightFilters();
    },

    addItem: function(mode) {
      if (mode === "form") {
        this.$router.push({
          name: "Project submission",
          params: {
            action: "create"
          }
        });
        return;
      }

      if (mode === "inline") {
        let newProject = {
          ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          "Project Name": "",
          "Start Date": moment.utc().format("YYYY-MM-DD"),
          "Finish Date": moment.utc().format("YYYY-MM-DD"),
          Progress: 0
        };

        /*
                let u = this.assignedTo.options.find(i => i.group_label === "Users");
                 newProject.assignedTo.push(
                 u.group_values.find(t => t.id === this.profile.data.id.toString())
                );*/

        this.dataTable.isInserting = true;

        this.dataTable.dataSet.unshift(newProject);
        //debugger

        Vue.set(this.dataTable.dataSet, 0, newProject);

        this.$nextTick(() => {
          if (!this.$refs.dataTable) return;

          var _this2 = this.$refs.dataTable.$children[0];

          _this2.editing.push({
            id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
            column: "Project Name",
            originalValue: ""
          });

          _this2.editing.push({
            id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
            column: "Start Date",
            originalValue: ""
          });
          _this2.editing.push({
            id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
            column: "Finish Date",
            originalValue: ""
          });

          this.toggleDescription(this.$constants.CUSTOM_TABLE.NEW_ROW_ID);
        });
      }
    },
    toggleDescription(id) {
      this.dataTable.childRow.showDescription = !(this.dataTable.childRow.showFiles = false);

      this.$refs.dataTable.toggleChildRow(id);
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Project submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Project submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let task = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Project #${task.ID} will be deleted. Tasks will be marked as non-owned. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`projects/${task.ID}`)
        .then(() => {
          self.$form.makeToastInfo("Project deleted");

          self.drawDataTable();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.data.message);

          self.drawDataTable();
        });
    },
    async getData(_payload) {
      if (_payload) this.serverFilter = _payload;

      this.drawDataTable();
    },

    async drawDataTable() {
      this.isLoading = true;

      let response = await this.$api.post(`projects`, this.serverFilter);

      this.rawData = response;

      this.isLoading = false;

      this.dataTable.dataSet = this.rawData;

      this.$emit("loaded", this.dataTable.dataSet.length);
    },
    onInlineUpdate(e) {
      console.log("ProjectsTable.onInlineUpdate", JSON.stringify(e));

      const columnsMapping = [
        { alias: "Project Name", dbName: "project_name" }
      ];

      let value = e.value;
      let dbName = columnsMapping.find(m => m.alias === e.column).dbName;

      this.updateField(e.id, dbName, value);
    },
    updateField(id, field, value) {
      let self = this;
      let params = {};
      params["id"] = id;
      params[field] = value;

      this.$api
        .put(`projects/${this.id}`, params)
        .then(response => {
          self.$form.makeToastInfo(`${field}: ${response.message}`);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(`${field}: ${response.message}`);
        });
    }
  }
};
</script>

<style scoped>
.highlight {
  background-color: #ffffed;
}
</style>
